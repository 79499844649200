import { pageSettingsType } from 'types/pages/pageContainer.types';
import { storefrontPageType } from 'humanity/pages/storefrontPage/storefrontPage.types';
import StorefrontPage from 'humanity/pages/storefrontPage';
import { getStorefrontPageSSG } from 'humanity/pages/storefrontPage/storefrontPage.query';

const IndexPage = ({ pageSettings, pageContent }) => (
  <StorefrontPage pageSettings={pageSettings} pageContent={pageContent} />
);

IndexPage.propTypes = {
  pageSettings: pageSettingsType.isRequired,
  pageContent: storefrontPageType.isRequired,
};

export const getStaticProps = getStorefrontPageSSG('index');

export default IndexPage;
